/*机票接口*/
import {postAjaxRequest} from '@/utils/request'
import {yqfApiurl,yqfComApiurl} from '@/utils/webconfig'

//机票搜索信息
export function GetTicketSearch(parm, callback) {
	console.log('GetTicketSearchInfo');
	postAjaxRequest('/FlightAPI/getTicketSearchInfo', parm, function(result) {
		callback(result);
	})	
}
//根据地址栏查询
export function GetTicketSearchByUrlParm(parm, callback) {
	console.log('GetTicketSearchByUrlParm');
	postAjaxRequest('/FlightAPI/GetTicketSearchByUrlParm', parm, function(result) {
		callback(result);
	})	
}
//获取查询的JOBID
export function GetSearchFSJobid(parm, callback) {
	console.log('GetSearchFSJobid');
	postAjaxRequest('/FlightAPI/GetSearchFSJobid', parm, function(result) {
		callback(result);
	})	
}
//查询机票列表信息
export function GetFlightDataByJobid(parm, callback) {
	console.log('GetFlightDataByJobid');
	postAjaxRequest('/FlightAPI/GetFlightDataByJobid', parm, function(result) {
		callback(result);
	})	
}
//根据订单号查询退改航班
export function GetSearchByOrder(parm, callback) {
	console.log('GetSearchByOrder');
	postAjaxRequest('/FlightAPI/GetSearchByOrder', parm, function(result) {
		callback(result);
	})	
}

//查询国内机票列表
export function GetDomesticFlightDataByJobid(parm, callback) {
	console.log('GetDomesticFlightDataByJobid');
	postAjaxRequest('/FlightAPI/GetDomesticFlightDataByJobid', parm, function(result) {
		callback(result);
	})	
}
//查询国内机票退改签
export function GetDomesticAirLimitConditionInfo(parm, callback) {
	console.log('GetDomesticAirLimitConditionInfo');
	postAjaxRequest('/FlightAPI/GetDomesticAirLimitConditionInfo', parm, function(result) {
		callback(result);
	})	
}
//获取航班列表
export function GetFlightList(parm, callback) {
	console.log('GetFlightList');
	postAjaxRequest('/FlightAPI/GetFlightList', parm, function(result) {
		callback(result);
	})	
}
//获取黑屏信息
export function GetSearchFlightTextDetail(parm, callback) {
	console.log('GetSearchFlightTextDetail');
	postAjaxRequest('/FlightAPI/GetSearchFlightTextDetail', parm, function(result) {
		callback(result);
	})	
}
//退改签信息
export function GetFareRuleInfo(parm, callback) {
	console.log('GetFareRuleInfo');
	postAjaxRequest('/FlightAPI/GetFareRuleInfo', parm, function(result) {
		callback(result);
	})	
}
//预订航班
export function BookFlight(parm, callback) {
	console.log('BookFlight');
	postAjaxRequest('/FlightAPI/BookFlight', parm, function(result) {
		callback(result);
	})	
}
//删除航班
export function RemoveBookFlight(parm, callback) {
	console.log('RemoveBookFlight');
	postAjaxRequest('/FlightAPI/RemoveBookFlight', parm, function(result) {
		callback(result);
	})	
}
//获取航班筛选信息
export function GetFilterFlightInfo(parm, callback) {
	console.log('GetFilterFlightInfo');
	postAjaxRequest('/FlightAPI/GetFilterFlightInfo', parm, function(result) {
		callback(result);
	})	
}

//验价
export function GetVeriflyFlightByBookFlight(parm, callback) {
	console.log('GetVeriflyFlightByBookFlight');
	postAjaxRequest('/FlightAPI/GetVeriflyFlightByBookFlight', parm, function(result) {
		callback(result);
	})	
}
//验价2
export function GetVeriflyFlightV2(parm, callback) {
	console.log('GetVeriflyFlightV2');
	postAjaxRequest('/FlightAPI/GetVeriflyFlightV2', parm, function(result) {
		callback(result);
	})	
}
/*PNR验价*/
export function GetVeriflyPNRFlight(parm, callback) {
	console.log('GetVeriflyPNRFlight');
	postAjaxRequest('/FlightAPI/GetVeriflyPNRFlight', parm, function(result) {
		callback(result);
	})	
}
/*PNR预订*/
export function GetPnrBook(parm, callback) {
	console.log('GetPnrBook');
	postAjaxRequest('/FlightAPI/GetPnrBook', parm, function(result) {
		callback(result);
	})	
}
/*预定的航班信息*/
export function GetPnrBookFlightInfo(parm, callback) {
	console.log('GetPnrBookFlightInfo');
	postAjaxRequest('/FlightAPI/GetPnrBookFlightInfo', parm, function(result) {
		callback(result);
	})	
}
/*查询PNR航班*/
export function GetFlightDataByPNR(parm, callback) {
	console.log('GetFlightDataByPNR');
	postAjaxRequest('/FlightAPI/GetFlightDataByPNR', parm, function(result) {
		callback(result);
	})	
}

export function GetConfirmBookFlightInfo(parm, callback) {
	console.log('GetConfirmBookFlightInfo');
	postAjaxRequest('/FlightAPI/GetConfirmBookFlightInfo', parm, function(result) {
		callback(result);
	})	
}

export function ImportTravellers(parm, callback) {
	console.log('ImportTravellers');
	postAjaxRequest('/FlightAPI/ImportTravellers', parm, function(result) {
		callback(result);
	})	
}
//获取推荐酒店信息
export function GetTuiJianHotelInfo(parm, callback) {
	console.log('GetTuiJianHotelInfo');
	postAjaxRequest('/FlightAPI/GetTuiJianHotelInfo', parm, function(result) {
		callback(result);
	})	
}
//获取推荐产品信息
export function GetBookTuijianInfo(parm, callback) {
	console.log('GetBookTuijianInfo');
	postAjaxRequest('/FlightAPI/GetBookTuijianInfo', parm, function(result) {
		callback(result);
	})	
}
//大家正在查
export function GetSearchingInfo(parm, callback) {
	console.log('GetSearchingInfo');
	postAjaxRequest('/FlightAPI/GetSearchingInfo', parm, function(result) {
		callback(result);
	})	
}

//获取航司协议
export function GetAirwaysCustomerRule(parm, callback) {
	console.log('GetAirwaysCustomerRule');
	postAjaxRequest('/FlightAPI/GetAirwaysCustomerRule', parm, function(result) {
		callback(result);
	})	
}
//特价机票
export function GetRouteMinPrice(parm, callback) {
	console.log('GetRouteMinPrice');
	postAjaxRequest('/FlightAPI/GetRouteMinPrice', parm, function(result) {
		callback(result);
	})	
}
//读取精选标签列表
export function GetGlobalDistrictLabels(parm, callback) {
	console.log('GetGlobalDistrictLabels');
	postAjaxRequest('/FlightAPI/GetGlobalDistrictLabels', parm, function(result) {
		callback(result);
	})	
}