import {
	getTicketSearchInfo,
	setTicketSearchInfo
} from '@/utils/localdatas';

var util = require('@/utils/util.js');
var staticData = require('@/data/staticData.js');
var datehelper = require('@/utils/datehelper.js');

export var thatobj;
//获取机票的查询信息
export function GetTicketSearchInfo() {
	var TicketSearchInfo = getTicketSearchInfo(); //获取本地存储
	if (TicketSearchInfo == null) {
		TicketSearchInfo = staticData.TicketSearchInfo;
	}
	// that.dualType = that.TicketSearchInfo.Dual;
	console.log(TicketSearchInfo);
	return TicketSearchInfo;
}
//选择城市 //cityType:0代表出发城市，1代表抵达城市
export function SelectCity(ticketSearchInfo, cityType, cityIdx, cityModel) {
	// var cityIdx = 0;
	var MultSegments = ticketSearchInfo.MultSegments;

	if (cityType == 0) {
		ticketSearchInfo.DepartCityCode = cityModel.CityCode;
		ticketSearchInfo.DepartCityName = cityModel.CityName;

		ticketSearchInfo.DepartInputVal = cityModel.CityName + '(' + cityModel.CityCode + ')';

		MultSegments[cityIdx].DepartCityCode = cityModel.CityCode;
		MultSegments[cityIdx].DepartCityName = cityModel.CityName;

		MultSegments[cityIdx].DepartInputVal = cityModel.CityName + '(' + cityModel.CityCode + ')';
	} else {
		// cityIdx=1;
		ticketSearchInfo.ArrivalCityCode = cityModel.CityCode;
		ticketSearchInfo.ArrivalCityName = cityModel.CityName;
		ticketSearchInfo.ArrivalInputVal = cityModel.CityName + '(' + cityModel.CityCode + ')';

		MultSegments[cityIdx].ArrivalCityCode = cityModel.CityCode;
		MultSegments[cityIdx].ArrivalCityName = cityModel.CityName;

		MultSegments[cityIdx].ArrivalInputVal = cityModel.CityName + '(' + cityModel.CityCode + ')';
	}
	if (ticketSearchInfo.Dual != 2) {
		cityIdx = 1;
		if (MultSegments.length == 2) {
			MultSegments[cityIdx].DepartCityCode = ticketSearchInfo.ArrivalCityCode;
			MultSegments[cityIdx].DepartCityName = ticketSearchInfo.ArrivalCityName;

			MultSegments[cityIdx].ArrivalCityCode = ticketSearchInfo.DepartCityCode;
			MultSegments[cityIdx].ArrivalCityName = ticketSearchInfo.DepartCityName;
		}
	}
	ticketSearchInfo.MultSegments = MultSegments;
	return ticketSearchInfo;
}
//切换城市
export function QieHuan(ticketSearchInfo,idx) {
	if (ticketSearchInfo.Dual != 2) {
		let departInputVal = ticketSearchInfo.DepartInputVal;
		let departCityName = ticketSearchInfo.DepartCityName;
		let departCityCode = ticketSearchInfo.DepartCityCode;

		let arrivalInputVal = ticketSearchInfo.ArrivalInputVal;
		let arrivalCityName = ticketSearchInfo.ArrivalCityName;
		let arrivalCityCode = ticketSearchInfo.ArrivalCityCode;

		ticketSearchInfo.DepartInputVal = arrivalInputVal;
		ticketSearchInfo.DepartCityName = arrivalCityName;
		ticketSearchInfo.DepartCityCode = arrivalCityCode;

		ticketSearchInfo.ArrivalInputVal = departInputVal;
		ticketSearchInfo.ArrivalCityName = departCityName;
		ticketSearchInfo.ArrivalCityCode = departCityCode;
	} else {
		var MultSegments = ticketSearchInfo.MultSegments;

		let departInputVal = MultSegments[idx].DepartInputVal;
		let departCityName = MultSegments[idx].DepartCityName;
		let departCityCode = MultSegments[idx].DepartCityCode;

		let arrivalInputVal = MultSegments[idx].ArrivalInputVal;
		let arrivalCityName = MultSegments[idx].ArrivalCityName;
		let arrivalCityCode = MultSegments[idx].ArrivalCityCode;

		MultSegments[idx].DepartInputVal = arrivalInputVal;
		MultSegments[idx].DepartCityName = arrivalCityName;
		MultSegments[idx].DepartCityCode = arrivalCityCode;

		MultSegments[idx].ArrivalInputVal = departInputVal;
		MultSegments[idx].ArrivalCityName = departCityName;
		MultSegments[idx].ArrivalCityCode = departCityCode;
		ticketSearchInfo.MultSegments = MultSegments;
	}
	return ticketSearchInfo;
}
//添加航段
export function AddSegment(ticketSearchInfo, dateIdx) {
	var MultSegments = ticketSearchInfo.MultSegments;
	console.log(MultSegments);
	if (MultSegments.length >= 9) {
		// util.wxalert('最多添加9个航段');
		return;
	}
	var prv_date = datehelper.DateTimeParse(MultSegments[MultSegments.length - 1].DepartDate);
	var next_date = datehelper.DateTimeParse(prv_date.addDays(5));
	var new_MultSegment = {
		DepartCityCode: MultSegments[MultSegments.length - 1].ArrivalCityCode,
		DepartCityName: MultSegments[MultSegments.length - 1].ArrivalCityName,
		DepartInputVal: MultSegments[MultSegments.length - 1].ArrivalInputVal,
		ArrivalCityCode: '',
		ArrivalCityName: '请选择城市',
		ArrivalInputVal: '',
		DepartDate: next_date.toString('yyyy-MM-dd')
		// date: next_date.toString('yyyy-MM-dd'),
		// shortdate: next_date.toString('MM-dd'),
		// dateweek: next_date.toString('WWW')
	};
	MultSegments.push(new_MultSegment); //console.log(MultiFlight);
	ticketSearchInfo.MultSegments = MultSegments;
	return ticketSearchInfo;
}
//删除航段
export function DelSegment(ticketSearchInfo, idx) {

	var MultSegments = ticketSearchInfo.MultSegments;
	if (MultSegments.length == 1) {
		// util.wxalert('最多添加9个航段');
		return;
	}
	MultSegments.splice(idx, 1);
	ticketSearchInfo.MultSegments = MultSegments;

	return ticketSearchInfo;
}
//选择日期
export function SelectDate(ticketSearchInfo,dateIdx,dateModel){
	var MultSegments = ticketSearchInfo.MultSegments;
	
	var dateIdx = that.dateIdx;
	console.log(dateIdx);
	console.log(MultSegments);
	MultSegments[dateIdx].DepartDate = dateModel.daystr;
	ticketSearchInfo.MultiStartDate[dateIdx] = dateModel.daystr;
	
	if (that.dateIdx == 0) {
		ticketSearchInfo.StartDate = dateModel.daystr;
	} else {
		ticketSearchInfo.ReturnDate = dateModel.daystr;
	}
	ticketSearchInfo.MultSegments = MultSegments;
	return ticketSearchInfo;
}
//获取查询机票的信息
async function getTicketSearchInfoJSON() {
	util.postAjaxRequest('/MiniAppFlights/GetTicketSearchInfo', null, function(result) {
		console.log(result);
		if (result.code == 0) {
			var TicketSearchInfo = result.data.TicketSearchInfo;
			console.log(TicketSearchInfo);
			// that.TicketSearchInfo = result.data.TicketSearchInfo;
			// setTicketSearchInfo(that.TicketSearchInfo);

			return TicketSearchInfo;
		}
	});
}

async function aaa() {
	return "111";

}
