/*目的地接口*/
import {postAjaxRequest} from '@/utils/request'
import {yqfApiurl,yqfComApiurl} from '@/utils/webconfig'
/*热门城市*/
export function GetHotCityList(parm, callback) {
	console.log('GetHotCityList');
	postAjaxRequest('/DestAPI/GetHotCityList', parm, function(result) {
		callback(result);
	})	
}
//关键字搜素
export function GetSearchCityListByKey(parm, callback) {
	console.log('GetSearchCityListByKey');
	postAjaxRequest('/DestAPI/GetSearchCityListByKey', parm, function(result) {
		callback(result);
	})	
}
//日期
export function GetCalender(parm, callback) {
	console.log('GetCalender');
	postAjaxRequest('/DestAPI/GetCalender', parm, function(result) {
		callback(result);
	})	
}
//航司列表
export function GetAirlineList(parm, callback) {
	console.log('GetAirlineList');
	postAjaxRequest('/DestAPI/GetAirlineList', parm, function(result) {
		callback(result);
	})	
}
export function GetAirlines(parm, callback) {
	console.log('GetAirlines');
	postAjaxRequest('/DestAPI/GetAirlines', parm, function(result) {
		callback(result);
	})	
}
//国家列表
export function GetCountrys(parm, callback) {
	console.log('GetCountrys');
	postAjaxRequest('/DestAPI/GetCountrys', parm, function(result) {
		callback(result);
	})	
}
//城市列表
export function GetCityList(parm, callback) {
	console.log('GetCityList');
	postAjaxRequest('/DestAPI/GetCityList', parm, function(result) {
		callback(result);
	})	
}
//获取酒店热门搜索数据
export function GetHotelSearchLog(parm, callback) {
	console.log('GetHotelSearchLog');
	postAjaxRequest('/DestAPI/GetHotelSearchLog', parm, function(result) {
		callback(result);
	})	
}

