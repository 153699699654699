<template>
	<div class="flight-box">
		<div class="container-wrap">
			<div class="banner">
				<div class="container">
					<div class="search-box">
						<el-tabs @tab-click="handleClick" v-model="TicketSearchInfo.Dual">
							
							<el-tab-pane label="单程" name="0" class="int-serch"></el-tab-pane>
							<el-tab-pane label="双程" name="1" class="int-serch"></el-tab-pane>
							<el-tab-pane label="多航段" name="2" class="int-serch"></el-tab-pane>
							
						</el-tabs>
						
						<!--单双程-->
						<div class="find-con clearfix" v-if="TicketSearchInfo.Dual != 2">
							<div class="reg-box1 block">
								<el-row class="position-r all-box" :gutter="24">
									<el-col :span="24" class="mb10">
										<label>出发城市</label>
										<div class="search-inp" id="scrollCity">
											<el-input
												v-model="TicketSearchInfo.DepartInputVal"
												size="medium"
												suffix-icon="iconfont icon-chalv-chufa icon-down"
												style="width:90%"
												@focus="bindShowCity($event, 0, 0)"
												@input="searchInput"
												@click.stop.native
											></el-input>
										</div>
									</el-col>
									<el-col :span="24" class="mb10">
										<label>抵达城市</label>
										<div class="search-inp" id="scrollCity1">
											<el-input
												v-model="TicketSearchInfo.ArrivalInputVal"
												size="medium"
												suffix-icon="iconfont icon-chalv-daoda icon-down"
												style="width:90%"
												@focus="bindShowCity($event, 0, 1)"
												@input="searchInput"
												@click.stop.native
											></el-input>
										</div>
									</el-col>
									<el-col :span="12" class="mb10">
										<label>出发日期</label>
										<div class="search-inp" @click="showCalendar(0)">
											<el-date-picker
												style="width:90%"
												size="medium"
												value-format="yyyy-MM-dd"
												type="date"
												placeholder="yyyy-MM-dd"
												:picker-options="pickerOptions0"
												v-model="TicketSearchInfo.StartDate"
											></el-date-picker>
										</div>
									</el-col>
						
									<el-col :span="12" class="mb10">
										<label class="">回程日期</label>
										<div class="search-inp" @click="showCalendar(1)">
											<el-date-picker
												style="width:90%"
												size="medium"
												value-format="yyyy-MM-dd"
												type="date"
												placeholder="yyyy-MM-dd"
												v-model="TicketSearchInfo.ReturnDate"
												:picker-options="pickerOptions1"
												:disabled="TicketSearchInfo.Dual == 0"
											></el-date-picker>
										</div>
									</el-col>
						
									<span class="int-transform" @click="bindQieHuan(0)"></span>
									<el-col :span="12" class="mb10">
										<span>舱位等级</span>
										<div class="choice-select search-inp">
											<el-select placeholder="请选择活动区域" size="medium" style="width:90%" v-model="TicketSearchInfo.Cabin">
												<el-option v-for="item in canbins" :key="item.value" :label="item.label" :value="item.value"></el-option>
											</el-select>
										</div>
									</el-col>
								</el-row>
								<el-row align="middle" type="flex" class="mb10">
									<el-col :span="12"><el-checkbox v-model="IsZhifei" label="1">直飞优先搜索</el-checkbox></el-col>
									<el-col :span="12"><el-button type="danger" class="search-btn-box" @click="bindSearch">搜索</el-button></el-col>
								</el-row>
							</div>
						</div>
						
						
						<div v-if="TicketSearchInfo.Dual == 2">
							<div class="city-box" v-for="(item, index) in TicketSearchInfo.MultSegments" :key="index">
								<div class="red-num">
									<span class="flight-red">{{ index + 1 }}</span>
								</div>
								<div class="icon-shanchu" @click="bindDelSegment(index)"><span class="iconfont icon-chalv-lajitong icon-guanbi icon-del-b"></span></div>
								<el-row class="mb10">
									<span>出发城市</span>
									<div class="search-inp" :id="'scrollCity' + index">
										<el-input
											v-model="item.DepartInputVal"
											size="medium"
											suffix-icon="iconfont icon-chalv-chufa icon-down"
											style="width:90%"
											@focus="bindShowCity($event, index, 0)"
											@input="searchInput"
											@click.stop.native
										></el-input>
									</div>
								</el-row>
								<el-row class="mb10">
									<span>抵达城市</span>
									<div class="search-inp" :id="'scrollCity1' + index">
										<el-input
											v-model="item.ArrivalInputVal"
											size="medium"
											suffix-icon="iconfont icon-chalv-daoda icon-down"
											style="width:90%"
											@focus="bindShowCity($event, index, 1)"
											@input="searchInput"
											@click.stop.native
										></el-input>
									</div>
								</el-row>
								<el-row class="mb10">
									<span>出发日期</span>
									<div class="search-inp" @click="showCalendar(1)">
										<el-date-picker
											style="width:90%"
											size="medium"
											type="date"
											value-format="yyyy-MM-dd"
											placeholder="yyyy-MM-dd"
											v-model="item.DepartDate"
											:picker-options="pickerOptions0"
										></el-date-picker>
									</div>
								</el-row>
							</div>
							<div class="add-leg-box"><span class="add-leg" @click="bindAddSegment">添加航班</span></div>
							<div class="city-box2">
								<el-row class="mb10">
									<el-col :span="12">
										<span>舱位等级</span>
										<div class="choice-select search-inp">
											<el-select placeholder="请选择活动区域" size="medium" style="width:90%" v-model="TicketSearchInfo.Cabin">
												<el-option v-for="item in canbins" :key="item.value" :label="item.label" :value="item.value"></el-option>
											</el-select>
										</div>
									</el-col>
								</el-row>
								<el-row align="middle" type="flex" class="mb10">
									<el-col :span="12"><el-radio v-model="radio" label="1">直飞优先搜索</el-radio></el-col>
									<el-col :span="12"><el-button type="danger" class="search-btn-box" @click="bindSearch">搜索</el-button></el-col>
								</el-row>
							</div>
					</div>
					</div>
				</div>
			</div>
			<div class="container">
				<el-row class="servicebox" type="flex">
					<div class="service-list"><a class="service-bg service1" target="_blank" href="http://huodong.yiqifei.com/2013/Service/seat.html"></a></div>
					<div class="service-list"><a class="service-bg service2" target="_blank" href="http://huodong.yiqifei.com/2013/Service/one.html"></a></div>
					<div class="service-list"><a class="service-bg service3" target="_blank" href="http://huodong.yiqifei.com/2013/Service/delate.html"></a></div>
					<div class="service-list"><a class="service-bg service4" target="_blank" href="http://huodong.yiqifei.com/2013/Service/"></a></div>
					<div class="service-list"><a class="service-bg service5" target="_blank" href="http://huodong.yiqifei.com/2013/Service/orders.html"></a></div>
				</el-row>
			</div>
		</div>
		<NewCity
			:showCity="showCity1"
			ref="cityAssembly"
			@clickCityDel="clickCityDel"
			@bindSelectCity="bindSelectCity"
			hotcitytype="plane"
			:top="y"
			:left="x"
			@click.stop.native
		></NewCity>
	</div>
</template>

<script>
import { getAccountInfo, setAccountInfo, clearLoginInfo } from '@/utils/localdatas';
import { GetHeadNav, GetMessageList, GetNotice } from '@/api/memberApi';
import { getTicketSearchInfo, setTicketSearchInfo } from '@/utils/localdatas';
import { duals, canbins, adults, childrens, TicketSearchInfo } from '@/data/staticData';

import NewCity from '../../components/newCity/newCity.vue';

var util = require('@/utils/util.js');
var datehelper = require('@/utils/datehelper.js');
var ticketSearchHelper = require('@/utils/ticketSearchHelper.js');
//接口
var flightApi = require('@/api/flightApi.js');

var that;
export default {
	components: {
		NewCity
	},
	data() {
		return {
			tabPosition: 'left',
			activeName: 'second',
			radio: '',
			value1: '',
			showCity1: false,
			showDate: false,
			cityIdx: 0,
			cityType: 0,
			dateIdx: 0,
			type: 1,
			x: 0,
			y: 0,

			tablabel: '往返程',
			IsZhifei:false,
			TicketSearchInfo: TicketSearchInfo,
			Duals: duals,
			canbins: canbins,
			adults: adults,
			pickerOptions0: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 8.64e7;
				}
			},
			pickerOptions1: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 8.64e7;
				}
			},
			airlineList: []
		};
	},

	created(props) {
		//生命周期里接收参数
		that = this;
		var query = that.$route.query;
		console.log(query);
		that.TicketSearchInfo = ticketSearchHelper.GetTicketSearchInfo();
		that.IsZhifei=TicketSearchInfo.SortType==2;
		console.log(that.TicketSearchInfo);
	},
	mounted() {
		// console.log(1);
		window.addEventListener('scroll', that.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
	},
	methods: {
		handleClick(tab, event) {
			console.log(tab.label, event);
			this.showCity1 = false;
			this.tablabel = tab.label;
		},
		searchInput(e) {
			console.log(e);
			this.$refs.cityAssembly.searchInput(e);
		},
		//选择城市
		bindShowCity(e, cityIdx, cityType) {
			if (that.TicketSearchInfo.IsChange) {
				return;
			}

			that.cityIdx = cityIdx;
			that.cityType = cityType;
			this.showCity1 = true;
			var rect = e.target.getBoundingClientRect();
			that.x = rect.left;
			that.y = rect.top;
			console.log(window.pageYOffset + '1');
			console.log(this.y);
			e.currentTarget.select();
		},		
		handleScroll() {
			// if (that.TicketSearchInfo.Dual == 2) {
			// 	var rect = document.querySelector('#scrollCity' + that.cityIdx).getBoundingClientRect();
			// } else {
			// 	var rect = document.querySelector('#scrollCity').getBoundingClientRect();
			// }
			if (that.cityType == 0) {
				if (that.tablabel == '') {
					var rect = document.querySelector('#scrollCity' + that.cityIdx).getBoundingClientRect();
				} else {
					var rect = document.querySelector('#scrollCity').getBoundingClientRect();
				}
			} else if (that.cityType == 1) {
				if (that.tablabel == '') {
					var rect = document.querySelector('#scrollCity1' + that.cityIdx).getBoundingClientRect();
				} else {
					var rect = document.querySelector('#scrollCity1').getBoundingClientRect();
				}
			}

			that.y = rect.top;
			console.log(window.pageYOffset + '1');
		},
		clickCityDel() {
			this.showCity1 = false;
		},
		//切换城市
		bindQieHuan(idx) {			
			that.TicketSearchInfo=ticketSearchHelper.QieHuan(that.TicketSearchInfo,idx);			
		},
		//选择城市
		bindSelectCity(cityModel) {
			that.showCity1 = false;
			var cityIdx = that.cityIdx;
			var cityType=that.cityType;
			console.log(cityModel);
			console.log(cityIdx);
			console.log(cityType);
						
			that.TicketSearchInfo=ticketSearchHelper.SelectCity(that.TicketSearchInfo,cityType,cityIdx,cityModel);
		},
		//选择日期
		showCalendar(dateIdx) {
			console.log(dateIdx);
			if (that.TicketSearchInfo.Dual == 0 && dateIdx == 1) {
				return;
			}
			that.dateIdx = dateIdx;
			this.showDate = true;
		},
		closeCalener() {
			this.showDate = false;
		},
		//选择日期
		bindSelectDate(dateModel) {
			console.log(dateModel);
			that.showDate = false;
			that.TicketSearchInfo = ticketSearchHelper.SelectDate( that.TicketSearchInfo,that.dateIdx,dateModel);
		},
		//添加航段
		bindAddSegment() {			
			that.TicketSearchInfo=ticketSearchHelper.AddSegment(that.TicketSearchInfo);
		},
		bindDelSegment(idx) {			
			that.TicketSearchInfo=ticketSearchHelper.DelSegment(that.TicketSearchInfo,idx);
		},
		//搜索
		bindSearch: function(e) {
			var TicketSearchInfo = that.TicketSearchInfo;
			if (TicketSearchInfo.Airline instanceof Array) {
				TicketSearchInfo.Airline = TicketSearchInfo.Airline[0];
			}
			if(that.IsZhifei){
				TicketSearchInfo.SortType=2;
			}
			else{
				TicketSearchInfo.SortType=1;
			}
			flightApi.GetTicketSearch(
				{
					ticketsearchinfo: JSON.stringify(TicketSearchInfo)
				},
				function(result) {
					// console.log(result);;
					if (result.code == 0) {
						TicketSearchInfo = result.data.TicketSearchInfo;
						console.log(TicketSearchInfo);
						setTicketSearchInfo(TicketSearchInfo);
						// that.$router.push({ path: '/IntlAir/list' });
						if (TicketSearchInfo.IsChinaCity) {
							that.$router.push({
								path: '/Flight/ShowDomesticFlight'
							});
						} else {
							that.$router.push({
								path: '/Flight/showIntlflight'
							});
						}
					} else {
						that.$message.error(result.msg);
					}
				}
			);
		}
	}
};

function getHeadNav(accountno) {
	GetHeadNav(null, function(result) {
		if (result.code == 0) {
			that.headNavs = result.data.headNavs;
		}
	});
}

function getMessageList(accountno) {
	let parm = {
		accountno: accountno
		// accountno: 'CMC0K38F',
		// pageindex:that.pageindex
	};
	GetMessageList(parm, function(result) {
		if (result.code == 0) {
			that.noticeCount = result.data.pageCount;
			if (that.noticeCount > 99) {
				that.noticeCount = '99+';
			}
		}
	});
}

function getNotice(accountno) {
	GetNotice(null, function(result) {
		if (result.code == 0) {
			that.NoticeText = result.data.NoticeText;
		}
	});
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../../style/Flight/flight.scss';
</style>
