<template>
	<div class="newcity-box" v-if="showCity === true" :style="'top:'+(top+38)+'px;left:'+left+'px'" style="border: 1px solid #ddd;">
		<!-- <div class="newcity-title">
			<span>城市列表</span>
			<div class="icon-cloc" @click="clickDel"><span class="iconfont icon-guanbi"></span></div>
		</div> -->
		<div class="newcity-input-box" style="padding: 0px;">
			<div  style="padding: 15px 0px 5px 10px; margin-bottom: 0px;color: #ccc;"  >支持拼音全称/英文全称/中文名/三字码</div>			
		</div>
		<div class="h400">
			<div style="position: relative;" class="newcity-inner-box" id="newcity-inner-box">
				<block v-if="searchVal!=''">
					<block v-if="searchCityResult==''">
						<el-row class="pd10" type="flex" >
							<!-- <span class="iconfont icon-meitiku06 icon-sousuo2"></span> -->
							<span class="iconfont icon-meitiku06 icon-sousuo2"></span>
							<block style="width:340px" class="cursor ml10">
								<div class="ft16">
									<span v-if="!isInSerach">抱歉，找不到：{{searchVal}}</span>
									<span v-else>正在查询...</span>
								</div>
								<div class="ft12 gray9">
									<!-- <span>{{ item.CountryName }}</span> -->
								</div>
							</block>
						</el-row>
					</block>
					<block v-else>
						<el-row class="pd10" v-for="(item, a) in searchCityResult" :key="a" type="flex" >
							<!-- <span class="iconfont icon-meitiku06 icon-sousuo2"></span> -->
							<span v-if="item.TypeID==20" class="iconfont icon-meitiku06 icon-jiudian"></span>
							<span v-else class="iconfont icon-meitiku06 icon-sousuo2"></span>
							<block @click="bindSelectAllCity(item)" style="width:340px" class="cursor ml10">
								<block v-if="hotcitytype == 'hotel'">
									<div class="ft16">
										<span>{{ item.CityNameCN }}</span>
										<!-- <span>{{ item.TypeName }}，{{ item.CityNameCN }}，{{ item.CountryName }}</span> -->
									</div>
									<div class="ft12 gray9">
										<span>{{ item.CountryName }}</span>
									</div>
								</block>
								<block v-else style="width:288px">
									<div class="ft16">
										<span>({{ item.CityCode }})</span>
										<span>
											{{ item.CityNameCN }}
											<block v-if="item.CountryName != ''">，{{ item.CountryName }}</block>
										</span>
									</div>
									<div class="ft12 gray9" style="cursor:pointer;">
										<span>
											{{ item.CityNameEN }},{{ item.CityCode }}
											<block v-if="item.CountryCode != ''">,{{ item.CountryCode }}</block>
										</span>
									</div>
								</block>
							</block>
							<span class="place-difan" style="font-size:13px;color:#999">{{item.TypeName }}</span>
						</el-row>
					</block>
				</block>
				<block v-else>
					<div>
						<div v-if="hotcitytype == 'hotel'" v-show="false">
							<div class="pd10 ft14"><span class="pl10">当前位置</span></div>
							<div>
								<div class="city8"><span>定位中...</span></div>
							</div>
							<div class="pd10 ft14"><span class="pl10">历史城市</span></div>
							<div>
								<div class="city8">
									<span>广州</span>
									<span class=" iconfont icon-shanchu icon-cha6"></span>
								</div>
								<div class="city8">
									<span>广州</span>
									<span class=" iconfont icon-shanchu icon-cha6 "></span>
								</div>
								<div class="city8">
									<span>广州</span>
									<span class=" iconfont icon-shanchu icon-cha6"></span>
								</div>
								<div class="city8">
									<span>广州</span>
									<span class=" iconfont icon-shanchu icon-cha6 "></span>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="pd10 ft14 pl20">热门城市</div> -->
					<div v-if="hotcitytype != 'trainticket'">
						<el-row type="flex" justify="space-between" class="date-box mt10" v-if="hotcitytype != 'trainticket'">
							<div :class="'w50-two' + (dateActive == 1 ? ' calendar-active' : '')" @click="changeDate(1)" style="cursor:pointer;">国内城市</div>
							<div :class="'w50-two' + (dateActive == 2 ? ' calendar-active' : '')" @click="changeDate(2)" style="cursor:pointer;">国际城市</div>
						</el-row>
					</div>
					<div v-if="dateActive == 1">
						<span style="cursor:pointer;" v-for="(item, a) in domesticHotCityList" :key="a" @click="bindSelectCity(item)"
						 class="city8">{{ item.CityName }}</span>
						<!-- <span class="city">广州</span>
					<span class="city">广州</span>
					<span class="city">广州</span> -->
					</div>
					<div v-if="dateActive == 2">
						<span style="cursor:pointer;" v-for="(item, a) in internationalHotCityList" :key="a" @click="bindSelectCity(item)"
						 class="city8">{{ item.CityName }}</span>
						<!-- <span class="city">广州</span>
					<span class="city">广州</span>
					<span class="city">广州</span> -->
					</div>
					<div>
						<div class="pl10 gray6 pr10 pb10">
							<span class="ft14">搜索</span>
							<span class="ft12 gray9">(我们支持全球民航城市的便捷搜索)</span>
						</div>
						<el-row type="flex" class="gray6 pr10 pb10 pl5">
							<el-col :span="6" style="width:30%;text-align: right;margin-right: 20px;">例如搜索温哥华可输或</el-col>
							<el-col :span="8">拼音全称：WENGEHUA 英文全称：VANCOUVER 中文全称：温哥华</el-col>
						</el-row>
					</div>
					<div>
						<block v-if="dateActive == '1'">
							<div v-if="hotcitytype == 'hotel'" v-show="false">
								<div class="pd10 ft14 pl20">字母检索</div>
								<span style="cursor:pointer;width:8%" v-for="(item, index, a) in ZMlettersList" :key="a" @click="ScrollToLetter(item, index)"
								 class="city8 latter-city">
									{{ item }}
								</span>
							</div>
						</block>

						<div v-if="dateActive == '1'">
							<div style="position: relative;" v-if="hotcitytype == 'hotel'" v-show="false">
								<div class="pd10 latter-position" v-for="(item, index, a) in ZMlettersList" :key="a" :id="'letter' + index">
									<div class="pd10 ft16">{{ item }}</div>
									<div class="letter-city-item">城市</div>
									<div class="letter-absolute"></div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="hotcitytype == 'hotel'" v-show="false">
						<div>
							<div class="pd10 ft14"><span class="">目的地索引</span></div>
							<el-row type="flex" class="">
								<div class="des-left-box">
									<div :class="AreaActive == index ? 'des-left-item des-left-itemActive' : 'des-left-item'" v-for="(item, index, a) in [1, 2, 3, 4, 5, 6, 7, 8]"
									 :key="a" @click="clickAreaActive(index)">
										东南亚
										<el-row style="margin-top:-10px" class="des-left-dot-box">
											<div v-if="AreaActive == index">
												<div class="des-left-bottom-dot"></div>
												<div class="des-left-bottom-dot"></div>
												<div class="des-left-bottom-dot"></div>
											</div>
										</el-row>
									</div>
								</div>
								<div class="des-right-box">
									<div v-for="(item, index, a) in [1, 2, 3, 4, 5, 6, 7, 8, 9]" :key="a">
										<div class="country-item">新加坡</div>
										<div class="country-item-item">新加坡(Singapore)</div>
										<div class="country-item-item">新加坡(Singapore)</div>
									</div>
								</div>
							</el-row>
						</div>
					</div>
				</block>
			</div>

			<div v-if="searchVal==''">
				<div v-if="dateActive == '1'" v-show="false">
					<div class="a-z-box" @click="toTop" v-if="hotcitytype == 'hotel'">
						<div><span class="iconfont icon-xiangshang11"></span></div>
						<div>A-Z</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var destApi = require('@/api/destApi.js');
	var domesticHotCityList = [];
	var internationalHotCityList = [];
	var hotcitylist = [];
	var ZMlettersList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S',
		'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
	];
	var that;
	export default {
		name: 'NewCity',
		props: ['showCity', 'hotcitytype', 'top', 'left'],
		data() {
			return {
				currentTab: 0,
				dateActive: '1',
				cityType: 0,
				domesticHotCityList: domesticHotCityList, //国内热门城市
				internationalHotCityList: internationalHotCityList, //国际热门城市
				searchCityResult: '', //搜索结果
				searchVal: '',
				isInSerach: false,
				ZMlettersList: ZMlettersList,
				AreaActive: 0,
			};
		},
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);
			console.log(that.hotcitytype + 'cityType');
			getHotCityList();
		},

		mounted() {
			// 此处true需要加上，不加滚动事件可能绑定不成功
			window.addEventListener('scroll', this.handleScroll, true);
		},

		methods: {
			handleScroll() {
				try {
					let scrolltop = document.getElementById('newcity-inner-box').scrollTop;
					scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
				} catch (e) {

				}
			},
			clickAreaActive(index) {
				that.AreaActive = index;
			},
			ScrollToLetter(letter, index) {
				var allHeiight = 700;
				for (var i = 0; i < index; i++) {
					console.log('letter' + i);
					allHeiight = allHeiight + document.getElementById('letter' + i).clientHeight;
				}
				let top = (document.getElementById('newcity-inner-box').scrollTop = allHeiight);
			},
			toTop() {
				let top = (document.getElementById('newcity-inner-box').scrollTop = 520);
			},
			clickDel() {
				this.$emit('clickCityDel');
			},
			changeDate(index) {
				console.log(index);
				this.dateActive = index;
			},
			//搜索事件
			searchInput: function(e) {
				that.isInSerach = true;
				var searchKey = e;
				that.searchVal = searchKey;
				console.log(e);
				// var searchKey = e.detail.value;
				destApi.GetSearchCityListByKey({
						searchKey: searchKey,
						hotcitytype: that.hotcitytype
					},
					function(result) {
						that.isInSerach = false;
						console.log(result);
						var searchResult = '';

						if (result.code == 0) {
							searchResult = JSON.parse(result.data.searchResult); // var hotelCityResult = JSON.parse(result.data.hotelCityResult);

							console.log(searchResult);
						}
						that.searchCityResult = searchResult;
					}
				);
			},
			//输入框获得焦点
			inputFocus: function() {
				that.isInSerach = true;
			},
			//输入框失去焦点
			inputBlur: function(e) {
				console.log(e);
				if (that.searchVal == '') {
					that.isInSerach = false;
				}
			},
			//选择城市
			bindSelectCity: function(e) {
				console.log(e);
				let citymodel = e;

				this.$emit('bindSelectCity', citymodel);
			},
			bindSelectAllCity: function(e) {
				console.log(e);

				var citydata = e;

				if (citydata != undefined && citydata != null) {
					let KeyWord = '';
					if (citydata.TypeID == 20 || citydata.TypeID == 9) {
						KeyWord = citydata.KeyWord;
					}
					var citymodel = {
						CityCode: citydata.CityCode,
						CityName: citydata.CityNameCN,
						CountryCode: citydata.CountryCode,
						IsChina: citydata.IsChina,
						KeyWord: KeyWord
					};
					console.log(citydata);
					this.$emit('bindSelectCity', citymodel);
				}
			},
			getHotCityList() {
				destApi.GetHotCityList({
						hotcitytype: that.hotcitytype
					},
					function(result) {
						// console.log(hotcitylist);
						hotcitylist = JSON.parse(result.data.hotcitylist);
						// console.log(hotcitylist + '222');
						domesticHotCityList = hotcitylist.filter(function(item) {
							return item.IsChina == true;
						});
						internationalHotCityList = hotcitylist.filter(function(item) {
							return item.IsChina == false;
						});
						that.domesticHotCityList = domesticHotCityList;
						that.internationalHotCityList = internationalHotCityList;

						console.log(domesticHotCityList.length, internationalHotCityList.length);
					}
				);
			}
		}
	};
	//获取热门城市列表
	function getHotCityList() {
		destApi.GetHotCityList({
				hotcitytype: that.hotcitytype
			},
			function(result) {
				// console.log(hotcitylist);
				hotcitylist = JSON.parse(result.data.hotcitylist);
				// console.log(hotcitylist + '222');
				domesticHotCityList = hotcitylist.filter(function(item) {
					return item.IsChina == true;
				});
				internationalHotCityList = hotcitylist.filter(function(item) {
					return item.IsChina == false;
				});
				that.domesticHotCityList = domesticHotCityList;
				that.internationalHotCityList = internationalHotCityList;

				console.log(domesticHotCityList.length, internationalHotCityList.length);
			}
		);
	}
</script>

<style lang="scss">
	@import './newCity.scss';
</style>
